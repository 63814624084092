import React, { useContext, useEffect, useState } from "react";
import iconClose from "../../images/iconClose.svg";
import { UpdateCaseContext } from "../../infrastructure/Context/UpdateCaseContext";
import CurrencyInput from "react-currency-input-field";

function FormCurrencyInput({ fieldName, label, value, setValue, readonly = false, isLinkedWithAPI = true }: {
    fieldName: string,
    label: string,
    value: string | undefined,
    setValue: (fieldName: string, value: string, hasValueChanged: boolean) => void,
    readonly?: boolean,
    isLinkedWithAPI?: boolean }) {
    const [inputValue, setInputValue] = useState(value || '');
    const [isModified, setIsModified] = useState(false);
    const [initialValue, setInitialValue] = useState(value);
    const { saveSuccess, setUnsavedChanges } = useContext(UpdateCaseContext);

    useEffect(() => {
        if (saveSuccess) {
            setInitialValue(inputValue);
            setUnsavedChanges(false);
        }
    }, [saveSuccess, inputValue, setUnsavedChanges]);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    useEffect(() => {
        setIsModified(inputValue !== initialValue && inputValue !== '' && initialValue !== undefined);
    }, [inputValue, initialValue]);

    function formatCurrency(value: string) {
        // Convert value to number
        const numberValue = parseFloat(value.replace(/[^\d.-]/g, ''));
        if(isNaN(numberValue)) return "";
        // Format the number as currency with decimal and thousand separators
        return numberValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'GBP'
        });
    }

    function parseCurrency(numberWithCommas: string | number) {
        if (numberWithCommas && typeof numberWithCommas === 'number') {
            numberWithCommas = numberWithCommas.toString();
        }
        if (numberWithCommas && typeof numberWithCommas === 'string') {
            const stringWithoutCommas = numberWithCommas.replace(/,/g, '');
            const floatValue = parseFloat(stringWithoutCommas);
            return floatValue
        }
        return undefined;
    }

    function onChange(value: string) {
        // Remove non-numeric characters from input
        const sanitizedValue = value.replace(/[^\d.]/g, '');

        setInputValue(sanitizedValue);
        let hasValueChanged = true;
        if (sanitizedValue !== initialValue) {
            setUnsavedChanges(true);
            setIsModified(sanitizedValue !== inputValue);
        } else {
            setUnsavedChanges(false);
            hasValueChanged = false;
        }
        setValue(fieldName, sanitizedValue, hasValueChanged);
    }

    function clearInput() {
        setInputValue("");
        setValue(fieldName, "", false);
        setIsModified(false);
        setUnsavedChanges(initialValue !== "");
    }

    const labelClass = isLinkedWithAPI ? "input-header" : "input-header to-do";

    return (
        <div className="input-container">
            <label htmlFor={fieldName} className={labelClass}>
                {label?.toUpperCase()}
                {isModified && <span className="edit-indicator"></span>}
            </label>

            <CurrencyInput
                id={fieldName}
                prefix="£"
                className={readonly ? "form-input form-input-readonly" : "form-input"}
                readOnly={readonly}
                defaultValue={parseCurrency(inputValue)}
                decimalsLimit={2}
                decimalScale={2}
                onChange={(e) => onChange(e.target.value)}
                // onValueChange={(value, name, values) => console.log(value, name, values)}
            />

            {/*
            <input
                value={formatCurrency(inputValue)}
                type="text"
                className="form-input"
                id={fieldName}
                onChange={(e) => onChange(e.target.value)}
            />
            */}
            {!readonly && (
                <img className="clear-val-btn" src={iconClose} alt="icon clearing value from input" onClick={clearInput}/>
            )}
        </div>
    );
};

export default FormCurrencyInput;
