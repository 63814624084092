import React, {useContext} from "react";
import FormTextArea from "../Form/FormTextArea";
import FormSelect from "../Form/FormSelect";
import ESummaryFieldTypes from "../../models/Case/UpdateCase/FieldTypes/ESummaryFieldTypes";
import {UpdateCaseContext} from "../../infrastructure/Context/UpdateCaseContext";
import FormDateInput from "../Form/FormDateInput";
import FormDecimalInput from "../Form/FormDecimalInput";
import moment from "moment";
import FormCurrencyInput from "../Form/FormCurrencyInput";

export default function ExitRepayment() {
  const {currentCase, setCase, currentCaseAssessorQuestions, setCaseAssessorQuestions, updateTabsModified, setUnsavedChanges} = useContext(UpdateCaseContext);

  function handleState(fieldName: string, value: string) {
    switch (fieldName) {
      case ESummaryFieldTypes.PaymentDate:
        if(currentCase.CaseApp != null)
          currentCase.CaseApp.ApplicationProgressDates.PaymentDate = value;
        break;

      case ESummaryFieldTypes.DynamicDataField3:
        currentCase.UpdateDynamicDataField(3, value);
        break;

      case ESummaryFieldTypes.DynamicDataField6:
        currentCase.UpdateDynamicDataField(6, value);
        break;

      case ESummaryFieldTypes.DynamicDataField7:
        currentCase.UpdateDynamicDataField(7, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ255:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(255, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ7:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(7, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ256:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(256, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ257:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(257, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ258:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(258, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ259:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(259, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ260:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(260, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ261:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(261, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ262:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(262, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ263:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(263, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ264:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(264, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ265:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(265, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ266:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(266, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ267:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(267, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ268:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(268, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ269:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(269, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ270:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(270, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ271:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(271, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ272:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(272, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ273:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(273, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ274:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(274, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ275:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(275, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ276:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(276, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ277:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(277, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ278:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(278, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ279:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(279, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ280:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(280, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ281:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(281, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ282:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(282, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ283:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(283, value);
        break;

      case ESummaryFieldTypes.CaseAssessorQ284:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(284, value);
        break;

      case ESummaryFieldTypes.CaseAssessorA129:
        currentCaseAssessorQuestions.UpdateQuestionAnswer(129, value);
        break;
    }
    setCase(currentCase);
    updateTabsModified("ExitRepayment", true); 
    setUnsavedChanges(true); 
  }

  return (
    <div className="form-layout">
    <div className="form-column">
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ255.toString()} label="Contracted Redemption Date" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(255)} setValue={handleState} />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ257.toString()}
          label="Exit Type if refinance"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(257)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(257)}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ259.toString()}
          label="Copy of Sales Memorandum Received"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(259)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ261.toString()} label="Refinance HOT received" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(261)} setValue={handleState} />

        <FormDecimalInput fieldName={ESummaryFieldTypes.CaseAssessorQ263.toString()} label="Actual Build Timescale (Months)" value={currentCaseAssessorQuestions.GetQuestionAnswer(263)} setValue={handleState} />

        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ264.toString()}
          label="Refinance valuation report received"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(264)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ266.toString()}
          label="EPC Rating (Post Works)"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(266)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(266)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ268.toString()}
          label="Name Solicitors instructed for redemption"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(268)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(268)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ270.toString()}
          label="Is Overrun Required?"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(270)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(270)}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ272} label="Extension Decline Letter issued" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(272)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ273.toString()} label="Extension letter issued" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(273)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ275.toString()}
          label="Extension/overrun fees received"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(275)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ277.toString()}
          label="Date of the latest redemption statement issued"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(277)}
          setValue={handleState}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ279.toString()}
          label="Sales to call for feedback etc"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(279)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(279)}
        />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ281.toString()} label="Spare 1" value={currentCaseAssessorQuestions.GetQuestionAnswer(281)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ283.toString()} label="Spare 3" value={currentCaseAssessorQuestions.GetQuestionAnswer(283)} setValue={handleState} />
        </div>
        <div className="form-column">
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ256.toString()}
          label="Exit Lender"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(256)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(256)}
        />
        <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ7.toString()}
          label="Actual Exit Strategy"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(7)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(7)}
        />
         <FormSelect
          fieldName={ESummaryFieldTypes.CaseAssessorQ258.toString()}
          label="Registration of our charge at Land Reg"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(258)}
          setValue={handleState}
          values={currentCaseAssessorQuestions.GetQuestionAnswerList(258)}
        />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ260.toString()} label="Actual sale price achieved" value={currentCaseAssessorQuestions.GetQuestionAnswer(260)} setValue={handleState} />
        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ262.toString()} label="Actual Total Build Costs" value={currentCaseAssessorQuestions.GetQuestionAnswer(262)} setValue={handleState} />

        <FormSelect 
        fieldName={ESummaryFieldTypes.CaseAssessorQ487.toString()} 
        label="Actual Finish Quality" 
        value={currentCaseAssessorQuestions.GetQuestionAnswer(487)} 
        setValue={handleState} 
        values={currentCaseAssessorQuestions.GetQuestionAnswerList(487)}
        />

        <FormCurrencyInput fieldName={ESummaryFieldTypes.CaseAssessorQ265.toString()} label="Refinance Valuation CMV" value={currentCaseAssessorQuestions.GetQuestionAnswer(265)} setValue={handleState} />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ267.toString()} label="Refinance offer received" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(267)} setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ269.toString()}
          label="Date Solicitors instructed for redemption"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(269)}
          setValue={handleState}
        />
        <FormDecimalInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ271.toString()}
          label="Number of extensions/overruns granted"
          value={currentCaseAssessorQuestions.GetQuestionAnswer(271)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.PaymentDate} label="Date of Latest Credit Search" value={currentCase.CaseApp != null ? 
              currentCase.CaseApp?.ApplicationProgressDates.getPaymentDate() : null}  setValue={handleState} />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ274.toString()}
          label="Extension letter signed and returned"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(274)}
          setValue={handleState}
        />
        <FormDateInput
          fieldName={ESummaryFieldTypes.CaseAssessorQ276.toString()}
          label="Extension / Overrun contracted redemption date"
          value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(276)}
          setValue={handleState}
        />
        <FormDateInput fieldName={ESummaryFieldTypes.CaseAssessorQ278.toString()} label="Case Redeemed" value={currentCaseAssessorQuestions.GetQuestionAnswerAsDate(278)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ280.toString()} label="Client testimonial provided" value={currentCaseAssessorQuestions.GetQuestionAnswer(280)} setValue={handleState} />
        <FormTextArea fieldName={ESummaryFieldTypes.CaseAssessorQ282.toString()} label="Spare 2" value={currentCaseAssessorQuestions.GetQuestionAnswer(282)} setValue={handleState} />
      </div>
    </div>
  );
}
